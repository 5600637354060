.character {
    align-items: center;
    justify-content: center;
    display: flex;
    border: 1px solid gray;
    margin-inline: 5px;
    border-radius: 7px;
    background-color: #EFEFEF;
}

.character-wrong {
    align-items: center;
    justify-content: center;
    display: flex;
    margin-inline: 5px;
    border: 1px solid red;
    border-radius: 7px;
    background-color: #EFEFEF;
}

.container {
    height: 45px;
}